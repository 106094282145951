
import { defineComponent, ref, onMounted } from 'vue'
import { notification } from 'ant-design-vue'
import { useRoute } from 'vue-router'

import subscriptionService from '../../../services/subscription'
import commonServices from '../../../services/common'

export default defineComponent({
  setup () {
    const loading = ref<boolean>(false)
    const route = useRoute()
    const subscriptionId = ref<string>(route.query.session_id as string)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const activateClient = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        console.log('subscriptionId', subscriptionId.value)
        const responce = await subscriptionService.activateClient({
          id: subscriptionId.value,
          profile_id: profile._id,
          type: localStorage.subscriptionType,
          available_credit: localStorage.subscriptionQuantity,
          total_credit: localStorage.subscriptionQuantity,
          quantity: localStorage.subscriptionQuantity,
          amount: localStorage.subscriptionTotal
        })
        localStorage.profile = JSON.stringify(responce.data.profile)
        localStorage.subscription = JSON.stringify(responce.data.subscription)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      if (subscriptionId.value) {
        console.log('id exist')
        activateClient()
      }
    })
    return {
      loading
    }
  }
})
